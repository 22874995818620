import { forwardRef, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import AddPhotosModal from "../components/AddPhotosModal";
import MiddleNavigation from "../components/MiddleNav";
import {
  MiddleButtons,
  MiddleButtonsLeftSide,
  AddPhotosButton,
  RemovePhotos,
  MiddleButtonsRightSide,
  CancelDiv,
  MiddlePhotoRemovalBox,
  Main,
  MiddlePhotosGrid,
  MiddleNavHeading,
  MiddlePhoto
} from "../styles/MyGallery.styled";
import { Waypoint } from "react-waypoint";

import ViewPhotoModal from "../components/ViewPhotoModal";
import { GalleryPhoto } from "../utils/types";
import { api_key, api_url } from "../utils/constants";
import { SelectChangeEvent } from "@mui/material";
import ViewPhotoGalleryModal from "../components/ViewPhotoGalleryModal";

interface Props {
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbar: React.Dispatch<React.SetStateAction<string>>;
  isMobile: boolean;
}

const MyGallery: React.FC<Props> = ({ setMobileNavOpen, setSnackbar, isMobile }) => {
  const token = sessionStorage.getItem("token");
  const addPhotosModalRef = useRef<HTMLDivElement>(null);
  const [removingPhotos, setRemovingPhotos] = useState(false);
  const [addPhotosModal, setAddPhotosModal] = useState(false);
  const [galleryPhotos, setGalleryPhotos] = useState<any[]>([]);
  const [photosToRemove, setPhotosToRemove] = useState<number[]>([]);
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [lazyFetchingAllowed, setLazyFetchingAllowed] = useState(false);
  const [lazyLoading, setLazyLoading] = useState(false);

  const [draggedImage, setDraggedImage] = useState<GalleryPhoto | null>(null);

  const [totalPhotos, setTotalPhotos] = useState<number>(0);
  const [selectedPhoto, setSelectedPhoto] = useState<GalleryPhoto | null>(null);
  const isPhotoSelected = selectedPhoto !== null;

  const handleDragStart = (e: any, image: any) => {
    setDraggedImage(image);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (e: any, targetImage: any) => {
    e.preventDefault();

    // galleryPhotos.map((photo, index) => {
    //   if (photo?.thumbnail === targetImage?.thumbnail) {
    //     const updatedOrderImages = { ...draggedImage, order: targetImage?.order };
    //     // setCityWithNewOrderOrShowValue(updatedOrderCity);

    //     console.log(updatedOrderImages)
    //     return draggedImage;
    //   }
    //   if (photo?.thumbnail === draggedImage?.thumbnail) {
    //     return targetImage;
    //   }
    //   return galleryPhotos;
    // });
    // setDraggedImage(null);
  };

  const removePhoto = async () => {
    if (!token || !selectedPhoto) return;

    setLoading(true);
    try {
      const response = await axios.delete(`${api_url}/photos/${selectedPhoto.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "api-key": api_key
        }
      });

      if (response?.status === 200) {
        setSelectedPhoto(null);
        setSnackbar("Photo removed successfully");
        void fetchGalleryPhotos();
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const fetchGalleryPhotos = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${api_url}/gallery-photos`,
        {
          page: 1
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );

      if (response?.status === 200) {
        if (response?.data?.data?.length >= 40) {
          setLazyFetchingAllowed(true);
        }
        setLoading(false);
        setCurrentPage(2);
        setGalleryPhotos(response?.data?.data);
        setTotalPhotos(response?.data?.total);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchMoreGalleryPhotos = async (page: any) => {
    setLazyFetchingAllowed(false);
    setLazyLoading(true);
    try {
      const response = await axios.post(
        `${api_url}/gallery-photos`,
        {
          page: page,
          is_mobile: isMobile
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );

      if (response?.status === 200) {
        setLazyLoading(false);
        if (response?.data?.data?.length !== 0) {
          setLazyFetchingAllowed(true);
        }
        setGalleryPhotos((prevPhotos) => [...prevPhotos, ...response?.data?.data]);
        setCurrentPage(currentPage + 1);
      }

      setTimeout(() => {}, 3500);
    } catch (error) {
      console.log(error);
      setLazyLoading(false);
    }
  };

  useEffect(() => {
    if (!token) return;

    fetchGalleryPhotos();
  }, [token]);

  const onChange = (imageList: any, addUpdateIndex: any) => {
    setUploadedImages(imageList);
  };

  const addPhotosToRemove = (photoId: number) => {
    if (!removingPhotos) return;
    const newPhotosToRemove = [...photosToRemove, photoId];
    setPhotosToRemove(newPhotosToRemove);
  };

  const removePhotoFromList = (photoIdToRemove: number) => {
    if (!removingPhotos) return;
    const newPhotosToRemove = photosToRemove.filter((photoId) => photoId !== photoIdToRemove);
    setPhotosToRemove(newPhotosToRemove);
  };

  const findPhotoWithRemovalState = (photoId: number): boolean => {
    if (!removingPhotos) return false;
    return photosToRemove.some((photo) => photo === photoId);
  };

  const cancelRemoval = () => {
    setRemovingPhotos(false);
    setPhotosToRemove([]);
  };

  const handlePositionChange = async (event: SelectChangeEvent<string>) => {
    if (!selectedPhoto) return;

    const { value } = event.target;

    try {
      await axios.post(
        `${api_url}/position`,
        {
          photo_id: selectedPhoto.id,
          new_position: value
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );

      setSelectedPhoto(null);
      void fetchGalleryPhotos();
    } catch {
      return;
    }
  };

  const removeGalleryPhotos = async () => {
    if (!token) return;

    setLoading(true);
    try {
      const response = await axios.post(
        `${api_url}/remove-from-gallery`,
        {
          photoIds: photosToRemove
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );

      if (response?.status === 200) {
        setGalleryPhotos(response?.data?.gallery?.data);
        setSnackbar("Photos removed successfully");
        setRemovingPhotos(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Main>
        <MiddleNavigation setMobileNavOpen={setMobileNavOpen} pageType="My Gallery" setSnackbar={setSnackbar} />

        <MiddleButtons>
          <MiddleButtonsLeftSide>
            <AddPhotosButton
              style={removingPhotos ? { backgroundColor: "#ce425740" } : {}}
              disabled={removingPhotos}
              onClick={() => setAddPhotosModal(true)}
            >
              Add Photos
            </AddPhotosButton>
            {galleryPhotos?.length === 0 ? null : (
              <RemovePhotos
                style={removingPhotos ? { color: "#00b4d840" } : {}}
                onClick={() => setRemovingPhotos(true)}
              >
                Remove Photos
              </RemovePhotos>
            )}
          </MiddleButtonsLeftSide>

          {removingPhotos && (
            <MiddleButtonsRightSide>
              <AddPhotosButton onClick={removeGalleryPhotos}>Delete Photos</AddPhotosButton>

              <CancelDiv onClick={cancelRemoval}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  fill="#00b4d8"
                  viewBox="0 0 384 512"
                  cursor="pointer"
                >
                  <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                </svg>
                <RemovePhotos>Cancel</RemovePhotos>
              </CancelDiv>
            </MiddleButtonsRightSide>
          )}
        </MiddleButtons>

        {loading ? (
          <MiddlePhotosGrid>
            <Oval
              visible={true}
              height="60"
              width="60"
              color="#00b4d8"
              secondaryColor="#00b4d8"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </MiddlePhotosGrid>
        ) : (
          <MiddlePhotosGrid>
            {galleryPhotos?.length === 0 ? (
              <MiddleNavHeading style={{ textAlign: "center", width: "100%" }}>No Photos to Display</MiddleNavHeading>
            ) : (
              galleryPhotos?.map((photo, index) => (
                <MiddlePhoto
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, photo)}
                  onDragOver={(e) => handleDragOver(e)}
                  onDrop={(e) => handleDrop(e, photo)}
                  isDragging={draggedImage && draggedImage === photo}
                  onClick={
                    findPhotoWithRemovalState(photo?.id)
                      ? () => removePhotoFromList(photo?.id)
                      : () => (removingPhotos ? addPhotosToRemove(photo?.id) : setSelectedPhoto(photo))
                  }
                  key={index}
                  style={{
                    cursor: "pointer",
                    backgroundImage: `url(${photo?.thumbnail})`
                  }}
                >
                  {removingPhotos &&
                    (findPhotoWithRemovalState(photo?.id) ? (
                      <MiddlePhotoRemovalBox style={{ backgroundColor: "#00b4d8" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px"
                          fill="white"
                          viewBox="0 0 384 512"
                          cursor="pointer"
                        >
                          <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                        </svg>
                      </MiddlePhotoRemovalBox>
                    ) : (
                      <MiddlePhotoRemovalBox />
                    ))}
                </MiddlePhoto>
              ))
            )}

            {lazyLoading && (
              <Oval
                visible={true}
                height="60"
                width="60"
                color="#00b4d8"
                secondaryColor="#00b4d8"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )}
            {lazyFetchingAllowed && <Waypoint onEnter={() => fetchMoreGalleryPhotos(currentPage)} />}
          </MiddlePhotosGrid>
        )}
      </Main>

      {addPhotosModal && (
        <AddPhotosModal
          forwardRef={addPhotosModalRef}
          isMobile={isMobile}
          setAddPhotosModal={setAddPhotosModal}
          token={token}
          setGalleryPhotos={setGalleryPhotos}
          uploadedImages={uploadedImages}
          onChange={onChange}
          setUploadedImages={setUploadedImages}
          setSnackbar={setSnackbar}
        />
      )}

      {isPhotoSelected && (
        <ViewPhotoGalleryModal
          totalPhotos={totalPhotos || 0}
          photo={selectedPhoto}
          handlePositionChange={handlePositionChange}
          setSelectedPhoto={setSelectedPhoto}
          removePhoto={removePhoto}
        />
      )}
    </>
  );
};

export default MyGallery;
