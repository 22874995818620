import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.75);
`;

export const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 14px 20px;
  border-radius: 24px;
  width: 40%;
  min-width: 400px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 6px;
  }

  @media screen and (max-width: 879px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const LeftNavLogo = styled.h1`
  color: white;
  text-align: center;
  font-weight: 900;
  font-size: 2.4rem;
  margin-top: 0.5rem;
`;

export const CloseNav = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const MobileLeftNavLinks = styled.ul`
  margin-top: 2rem;
  display: flex;
  font-size: 1.2rem;
  flex-direction: column;
  align-items: left;
  list-style-type: none;
  color: white;
  cursor: pointer;
  font-weight: 400;

  li {
    padding-top: 2rem;
  }
`;
