import styled from "styled-components";

export const SnackbarContainer = styled.div`
  position: fixed;
  bottom: 5%;
  left: 50%;
  z-index: 999 !important;
  transform: translateX(-50%);
  width: 30%;
  height: 50px;
  background-color: #1d3557;
  border-radius: 1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  padding: 0.8rem;
  -webkit-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);

  @media screen and (max-width: 879px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const SnackbarIcon = styled.svg`
  fill: white;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
