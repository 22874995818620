import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import PrivateRoutes from "./routes/PrivateRoutes";
import MyGallery from "./pages/MyGallery";
import SharedWithMe from "./pages/SharedWithMe";
import NotFound from "./pages/NotFound";
import Snackbar from "./components/Snackbar";

function App() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [snackbar, setSnackbar] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = ["android", "iphone", "ipad", "ipod", "blackberry", "windows phone"];

    const isMobileDevice = mobileKeywords.some((keyword) => userAgent.includes(keyword));

    setIsMobile(isMobileDevice);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 876) setMobileNavOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (snackbar === "") return;
    setTimeout(() => {
      setSnackbar("");
    }, 2000);
  }, [snackbar]);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login setSnackbar={setSnackbar} />} />

          <Route
            element={
              <PrivateRoutes
                mobileNavOpen={mobileNavOpen}
                setMobileNavOpen={setMobileNavOpen}
                setSnackbar={setSnackbar}
              />
            }
          >
            <Route
              path="/my-gallery"
              element={<MyGallery isMobile={isMobile} setMobileNavOpen={setMobileNavOpen} setSnackbar={setSnackbar} />}
            />
            <Route
              path="/shared-with-me"
              element={
                <SharedWithMe setMobileNavOpen={setMobileNavOpen} setSnackbar={setSnackbar} isMobile={isMobile} />
              }
            />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

      {snackbar !== "" && <Snackbar text={snackbar} />}
    </>
  );
}

export default App;
