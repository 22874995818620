import styled from "styled-components";

export const MiddleNav = styled.div`
  width: 100%;
  height: 10dvh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  -webkit-box-shadow: 0px 9px 24px -18px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 9px 24px -18px rgba(66, 68, 90, 1);
  box-shadow: 0px 9px 24px -18px rgba(66, 68, 90, 1);
`;

export const MiddleNavHeading = styled.h2`
  font-size: 1.8rem;
  letter-spacing: 1px;
  color: rgba(66, 68, 90, 1);
  font-weight: 400;
`;

export const MiddleNavLogout = styled.p`
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: #00b4d8;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;

  @media screen and (max-width: 879px) {
    display: none;
  }
`;

export const MiddleNavHamburger = styled.div`
  display: none;
  cursor: pointer;

  @media screen and (max-width: 879px) {
    display: flex;
  }
`;
