import axios, { all } from "axios";
import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import ImageUploading from "react-images-uploading";
import {
  ModalOverlay,
  ModalDiv,
  ModalHeading,
  ModalCloseIcon,
  PhotosGrid,
  ModalInfo,
  Photo,
  PhotoRemovalBox,
  ModalFooter
} from "../styles/AddPhotosModal.styled";
import { AddPhotosButton } from "../styles/MyGallery.styled";
import { Waypoint } from "react-waypoint";
import { api_key, api_url } from "../utils/constants";

interface Props {
  setAddPhotosModal: React.Dispatch<React.SetStateAction<boolean>>;
  token: string | null;
  setGalleryPhotos: React.Dispatch<React.SetStateAction<any[]>>;
  uploadedImages: any[];
  onChange: (imageList: any, addUpdateIndex: any) => void;
  setUploadedImages: React.Dispatch<React.SetStateAction<any[]>>;
  setSnackbar: React.Dispatch<React.SetStateAction<string>>;
  isMobile: boolean;
  forwardRef: React.RefObject<HTMLDivElement>;
}

const AddPhotosModal: React.FC<Props> = ({
  setAddPhotosModal,
  token,
  setGalleryPhotos,
  uploadedImages,
  onChange,
  setUploadedImages,
  setSnackbar,
  isMobile,
  forwardRef
}) => {
  const [photosToAdd, setPhotosToAdd] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [allPhotos, setAllPhotos] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [lazyFetchingAllowed, setLazyFetchingAllowed] = useState(false);
  const [lazyLoading, setLazyLoading] = useState(false);

  const fetchAllPhotos = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${api_url}/all-photos`,
        {
          page: 1,
          is_mobile: isMobile
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );

      if (response?.status === 200) {
        if (response?.data?.data?.length >= 40) {
          setLazyFetchingAllowed(true);
        }
        setCurrentPage(2);
        setAllPhotos(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchMorePhotos = async (page: any) => {
    setLazyFetchingAllowed(false);
    setLazyLoading(true);
    try {
      const response = await axios.post(
        `${api_url}/all-photos`,
        {
          page: page,
          is_mobile: isMobile
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );

      if (response?.status === 200) {
        setLazyLoading(false);
        if (response?.data?.data?.length !== 0) {
          setLazyFetchingAllowed(true);
        }
        setAllPhotos((prevPhotos) => [...prevPhotos, ...response?.data?.data]);
        setCurrentPage(currentPage + 1);
      }

      setTimeout(() => {}, 3500);
    } catch (error) {
      console.log(error);
      setLazyLoading(false);
    }
  };

  useEffect(() => {
    if (!token) return;

    fetchAllPhotos();
  }, [token]);

  const addPhotosToAdd = (photoId: number) => {
    const newPhotosToRemove = [...photosToAdd, photoId];
    setPhotosToAdd(newPhotosToRemove);
  };

  const removePhotoFromList = (photoIdToRemove: number) => {
    const newPhotosToRemove = photosToAdd.filter((photoId) => photoId !== photoIdToRemove);
    setPhotosToAdd(newPhotosToRemove);
  };

  const findPhotoWithRemovalState = (photoId: number): boolean => {
    return photosToAdd.some((photo) => photo === photoId);
  };

  const addPhotos = async () => {
    if (!token) return;

    setLoading(true);
    try {
      const response = await axios.post(
        `${api_url}/add-to-gallery`,
        {
          photoIds: photosToAdd
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );

      setGalleryPhotos(response?.data?.gallery?.data);
      setAllPhotos(response?.data?.non_gallery?.data);
      setSnackbar("Photo added successfully");
      setAddPhotosModal(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const uploadPhotos = async () => {
    if (!token) return;
    setLoading(true);

    try {
      const response = await axios.post(
        `${api_url}/upload`,
        {
          base64image: uploadedImages[0]?.data_url,
          extension: uploadedImages[0]?.file?.type?.replace("image/", "")
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": "e6adI4aXyZooWT2LuWZFaQ93ub3jr202"
          }
        }
      );
      setSnackbar("Photo uploaded successfully");
      setAllPhotos(response?.data?.original?.data);
      setUploadedImages([]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (uploadedImages?.length > 0) {
      uploadPhotos();
    }
  }, [uploadedImages]);

  return (
    <ModalOverlay>
      <ModalDiv ref={forwardRef}>
        <ModalHeading>Add Photos to Gallery</ModalHeading>
        <ModalCloseIcon
          onClick={() => setAddPhotosModal(false)}
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 384 512"
          cursor="pointer"
        >
          <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
        </ModalCloseIcon>

        {loading ? (
          <PhotosGrid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {" "}
            <Oval
              visible={true}
              height="60"
              width="60"
              color="#00b4d8"
              secondaryColor="#00b4d8"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </PhotosGrid>
        ) : (
          <PhotosGrid>
            {allPhotos?.length === 0 ? (
              <ModalInfo>No Available photos</ModalInfo>
            ) : (
              allPhotos?.map((photo, index) => (
                <Photo
                  onClick={
                    findPhotoWithRemovalState(photo?.id)
                      ? () => removePhotoFromList(photo?.id)
                      : () => addPhotosToAdd(photo?.id)
                  }
                  key={index}
                  style={{
                    backgroundImage: `url(${photo?.middlesize_url})`
                  }}
                >
                  {findPhotoWithRemovalState(photo?.id) ? (
                    <PhotoRemovalBox style={{ backgroundColor: "#00b4d8" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        fill="white"
                        viewBox="0 0 384 512"
                        cursor="pointer"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    </PhotoRemovalBox>
                  ) : (
                    <PhotoRemovalBox />
                  )}
                </Photo>
              ))
            )}

            {lazyLoading && (
              <Oval
                visible={true}
                height="60"
                width="60"
                color="#00b4d8"
                secondaryColor="#00b4d8"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )}
            {lazyFetchingAllowed && <Waypoint onEnter={() => fetchMorePhotos(currentPage)} />}
          </PhotosGrid>
        )}
      </ModalDiv>
      <ModalFooter>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px"
          }}
        >
          <AddPhotosButton
            style={
              photosToAdd?.length === 0
                ? { backgroundColor: "#ce425740" }
                : allPhotos?.length === 0
                  ? { backgroundColor: "#ce425740" }
                  : {}
            }
            disabled={photosToAdd?.length === 0 || allPhotos?.length === 0}
            onClick={addPhotos}
          >
            Add Photos
          </AddPhotosButton>
          <ImageUploading multiple value={uploadedImages} onChange={onChange} maxNumber={69} dataURLKey="data_url">
            {({ imageList, onImageUpload, isDragging, dragProps }) => (
              <>
                <AddPhotosButton
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Upload Photos
                </AddPhotosButton>
              </>
            )}
          </ImageUploading>
        </div>
      </ModalFooter>
    </ModalOverlay>
  );
};

export default AddPhotosModal;
