import React from "react";

import {
  DeleteButton,
  ModalCloseIcon,
  ModalDiv,
  ModalHeading,
  ModalImageContainer,
  ModalOverlay,
  Photo
} from "../styles/ViewPhotoModal.styled";

import { GalleryPhoto } from "../utils/types";
import { PositionSelect } from "./PositionSelect";
import { Grid, SelectChangeEvent } from "@mui/material";

interface Props {
  totalPhotos: number;
  photo: GalleryPhoto;
  handlePositionChange: (event: SelectChangeEvent<string>) => Promise<void>;
  setSelectedPhoto: React.Dispatch<React.SetStateAction<GalleryPhoto | null>>;
  removePhoto: () => Promise<void>;
}

const ViewPhotoModal: React.FC<Props> = ({
  totalPhotos,
  photo,
  handlePositionChange,
  setSelectedPhoto,
  removePhoto
}) => {
  if (!photo) return <></>;

  const totalPhotosArray = Array.from({ length: totalPhotos }, (_, i) => i + 1);

  return (
    <ModalOverlay>
      <ModalHeading>
        <DeleteButton onClick={removePhoto}>Delete</DeleteButton>
        <ModalCloseIcon
          onClick={() => setSelectedPhoto(null)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          cursor="pointer"
          fill="white"
        >
          <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
        </ModalCloseIcon>
      </ModalHeading>
      <ModalDiv>
        <Grid container px={4}>
          <PositionSelect
            position={photo.position}
            handlePositionChange={handlePositionChange}
            totalPhotosArray={totalPhotosArray}
          />
        </Grid>
        <ModalImageContainer>
          <Photo src={photo.middlesize_url || ""} alt="Your Photo Alt Text" />
        </ModalImageContainer>
      </ModalDiv>
    </ModalOverlay>
  );
};

export default ViewPhotoModal;
