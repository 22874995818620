import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalDiv = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 14px 20px;
  border-radius: 24px;
  max-height: 70vh;
  min-width: 400px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 6px;
  }

  @media screen and (max-width: 879px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const ModalFooter = styled.div`
  letter-spacing: 1px;
  color: rgba(66, 68, 90, 1);
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 999;
  width: 40%;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 879px) {
    width: 100%;
    min-width: 100%;
    padding: 0px 8px;
  }
`;

export const ModalHeading = styled.h2`
  font-size: 1.8rem;
  letter-spacing: 1px;
  color: rgba(66, 68, 90, 1);
  font-weight: 400;
`;

export const ModalInfo = styled.p`
  font-size: 1.4rem;
  letter-spacing: 1px;
  color: rgba(66, 68, 90, 1);
  font-weight: 400;
  width: 100%;
  text-align: center;
`;

export const ModalCloseIcon = styled.svg`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const PhotosGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 2rem;
`;

export const Photo = styled.div`
  min-width: 160px;
  flex: 1;
  height: 160px;
  border-radius: 2px;
  -webkit-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  background-size: cover;
  background-position: center;
  padding: 1rem;
  border: 6px solid white;
  position: relative;
  cursor: pointer;
`;

export const PhotoRemovalBox = styled.div`
  position: absolute;
  z-index: 100;
  right: 10px;
  bottom: 10px;
  height: 36px;
  width: 36px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddPhotosButton = styled.button`
  border: none;
  background-color: #ce4257;
  color: white;
  border-radius: 8px;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  width: 200px;
  font-size: 1rem;
  letter-spacing: 1px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  display: block;

  @media screen and (max-width: 879px) {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
`;
