import { useLocation, useNavigate } from "react-router-dom";
import { LeftNavDiv, LeftNavLogo, LeftNavLinks } from "../styles/LeftNav.styled";

const LeftNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;
  return (
    <LeftNavDiv>
      <LeftNavLogo>
        <img
          style={{ width: "200px", height: "40px" }}
          src="https://topshot-brian.s3.us-east-2.amazonaws.com/production/images/logo.png"
          alt="ShareTag"
        />
      </LeftNavLogo>

      <LeftNavLinks>
        <li
          style={currentPath === "/shared-with-me" ? { background: "linear-gradient(#1d3557, #3b4c64)" } : {}}
          onClick={() => navigate("/shared-with-me")}
        >
          Shared With Me
        </li>
        <li
          style={currentPath === "/my-gallery" ? { background: "linear-gradient(#1d3557, #3b4c64)" } : {}}
          onClick={() => navigate("/my-gallery")}
        >
          My Gallery
        </li>
      </LeftNavLinks>
    </LeftNavDiv>
  );
};

export default LeftNav;
