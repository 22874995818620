import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 14px 0px;
  height: 80dvh;
  max-height: 80dvh;
  width: 40%;
  min-width: 400px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 100%;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 6px;
  }

  @media screen and (max-width: 879px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const ModalHeading = styled.div`
  letter-spacing: 1px;
  color: rgba(66, 68, 90, 1);
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 40px;
  width: 40%;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 879px) {
    width: 100%;
    min-width: 100%;
    padding: 0px 8px;
  }
`;

export const ModalCloseIcon = styled.svg`
  width: 20px;
  height: 20px;
`;

export const ModalImageContainer = styled.div`
  height: 80%;
  width: 100%;
  text-align: center;
  margin: 2rem 0 0 0;
`;

export const Photo = styled.img`
  width: 100%;
  /*height: 90%;*/
  object-fit: contain;
  border-radius: 2px;
  /*-webkit-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);*/
  /*padding: 1rem;*/
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  border: none;
  background-color: #ce4257;
  color: white;
  border-radius: 8px;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  width: 200px;
  font-size: 1rem;
  letter-spacing: 1px;
  cursor: pointer;

  @media screen and (max-width: 879px) {
    font-size: 0.9rem;
    padding: 0.75rem;
  }
`;
