import styled from "styled-components";

export const Main = styled.div`
  min-height: 100dvh;
  width: 100%;

  @media screen and (max-width: 879px) {
    width: 100%;
  }
`;

export const MiddleNavHeading = styled.h2`
  font-size: 1.8rem;
  letter-spacing: 1px;
  color: rgba(66, 68, 90, 1);
  font-weight: 400;
`;

export const MiddlePhotosGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 1rem 2rem;
  height: 76dvh;
  overflow: auto;

  @media screen and (max-width: 879px) {
    justify-content: center;
  }
`;

interface Props {
  isDragging: boolean | null;
}

export const MiddlePhoto = styled.div<Props>`
  width: 200px;
  height: 200px;
  border-radius: 2px;
  -webkit-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  background-size: cover;
  background-position: center;
  padding: 1rem;
  border: 6px solid white;
  position: relative;
  cursor: pointer;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};

  @media screen and (max-width: 879px) {
    width: 100px;
    height: 100px;
  }
`;

export const MiddleButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 2rem;
  height: 14dvh;
`;

export const MiddleButtonsLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const MiddleButtonsRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const CancelDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const AddPhotosButton = styled.button`
  border: none;
  background-color: #ce4257;
  color: white;
  border-radius: 8px;
  border: 2px solid #fff;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  width: 200px;
  font-size: 1rem;
  letter-spacing: 1px;
  cursor: pointer;

  @media screen and (max-width: 879px) {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
`;

export const RemovePhotos = styled.p`
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: #00b4d8;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;

  @media screen and (max-width: 879px) {
    font-size: 1.1rem;
  }
`;

export const MiddlePhotoRemovalBox = styled.div`
  position: absolute;
  z-index: 100;
  right: 10px;
  bottom: 10px;
  height: 36px;
  width: 36px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
