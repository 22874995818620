import styled from "styled-components";

export const NotFoundSection = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const NotFoundDiv = styled.div`
  width: 450px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #eeeeee80;
  border-radius: 12px;
`;

export const NotFoundHeading = styled.h1`
  font-size: 2rem;
  letter-spacing: 1px;
  color: rgba(66, 68, 90, 1);
  font-weight: 400;
`;
