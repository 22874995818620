import { useEffect, useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import ViewPhotoModal from "../components/ViewPhotoModal";
import MiddleNavigation from "../components/MiddleNav";
import { Main, MiddleNavHeading, MiddlePhoto, MiddlePhotosGrid, PageDiv } from "../styles/SharedWithMe.styled";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Waypoint } from "react-waypoint";

interface Props {
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbar: React.Dispatch<React.SetStateAction<string>>;
  isMobile: boolean;
}

const api_key = "OSGzfTKjs9jCSuxUaITqpz67BNg5UZHe";
const api_url = "https://brian-api.sharetag.ai/api";

const MyGallery: React.FC<Props> = ({ setMobileNavOpen, setSnackbar, isMobile }) => {
  const token = sessionStorage.getItem("token");
  const [allPhotos, setAllPhotos] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [viewPhotoModal, setViewPhotoModal] = useState(false);
  const [photoInModal, setPhotoInModal] = useState("");
  const [photoToRemove, setPhotoToRemove] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [lazyFetchingAllowed, setLazyFetchingAllowed] = useState(false);
  const [lazyLoading, setLazyLoading] = useState(false);

  const fetchAllPhotos = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${api_url}/all-photos`,
        {
          page: 1,
          is_mobile: isMobile
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );

      if (response?.status === 200) {
        if (response?.data?.data?.length >= 40) {
          setLazyFetchingAllowed(true);
        }
        setAllPhotos(response?.data?.data);
        setCurrentPage(2)
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchMorePhotos = async (page: any) => {
    setLazyFetchingAllowed(false);
    setLazyLoading(true);
    try {
      const response = await axios.post(
        `${api_url}/all-photos`,
        {
          page: page,
          is_mobile: isMobile
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );

      if (response?.status === 200) {
        setLazyLoading(false);
        if (response?.data?.data?.length !== 0) {
          setLazyFetchingAllowed(true);
        }
        setAllPhotos((prevPhotos) => [...prevPhotos, ...response?.data?.data]);
        setCurrentPage(currentPage + 1);
      }

      setTimeout(() => {}, 3500);
    } catch (error) {
      console.log(error);
      setLazyLoading(false);
    }
  };

  useEffect(() => {
    if (!token) return;
    console.log('fetch')
    void fetchAllPhotos();
  }, [token]);

  const viewPhotoInModal = (photo: string, photoId: number) => {
    setPhotoInModal(photo);
    setViewPhotoModal(true);
    setPhotoToRemove(photoId);
  };

  const removePhoto = async () => {
    if (!token) return;

    setLoading(true);
    try {
      const response = await axios.delete(`${api_url}/photos/${photoToRemove}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "api-key": api_key
        }
      });

      if (response?.status === 200) {
        setSnackbar("Photo removed successfully");
        setAllPhotos(response?.data?.original?.data);
        // setPageCount(response?.data?.original?.last_page);
        setViewPhotoModal(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <Main>
        <MiddleNavigation setMobileNavOpen={setMobileNavOpen} pageType="Shared With Me" setSnackbar={setSnackbar} />

        {loading ? (
          <MiddlePhotosGrid>
            <Oval
              visible={true}
              height="60"
              width="60"
              color="#00b4d8"
              secondaryColor="#00b4d8"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </MiddlePhotosGrid>
        ) : (
          <MiddlePhotosGrid>
            {allPhotos?.length === 0 ? (
              <MiddleNavHeading style={{ textAlign: "center", width: "100%" }}>No Photos to Display</MiddleNavHeading>
            ) : (
              allPhotos?.map((photo, index) => (
                <MiddlePhoto
                  onClick={() => viewPhotoInModal(photo?.url, photo?.id)}
                  key={index}
                  style={{
                    backgroundImage: `url(${photo?.thumbnail})`
                  }}
                ></MiddlePhoto>
              ))
            )}

            {lazyLoading && (
              <Oval
                visible={true}
                height="60"
                width="60"
                color="#00b4d8"
                secondaryColor="#00b4d8"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )}
            {lazyFetchingAllowed && <Waypoint onEnter={() => fetchMorePhotos(currentPage)} />}
          </MiddlePhotosGrid>
        )}
      </Main>

      {viewPhotoModal && (
        <ViewPhotoModal photo={photoInModal} setViewPhotoModal={setViewPhotoModal} removePhoto={removePhoto} />
      )}
    </>
  );
};

export default MyGallery;
