import { NotFoundDiv, NotFoundHeading, NotFoundSection } from "../styles/NotFound.styled";

const NotFound: React.FC = () => {
  return (
    <NotFoundSection
      style={{
        backgroundImage: `url(https://images.pexels.com/photos/3024996/pexels-photo-3024996.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)`
      }}
    >
      <NotFoundDiv>
        <NotFoundHeading>Page Not Found</NotFoundHeading>
      </NotFoundDiv>
    </NotFoundSection>
  );
};

export default NotFound;
