import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface Props {
  position: string;
  handlePositionChange: (event: SelectChangeEvent<string>) => Promise<void>;
  totalPhotosArray: number[];
}

export const PositionSelect: React.FC<Props> = ({ position, handlePositionChange, totalPhotosArray }) => {
  return (
    <Box sx={{ minWidth: 200 }}>
      <FormControl fullWidth>
        <InputLabel id="position-select-label">Position</InputLabel>
        <Select
          labelId="position-select-label"
          id="position-select"
          value={position}
          label="Position"
          onChange={handlePositionChange}
        >
          {totalPhotosArray.map((photoNumber) => (
            <MenuItem key={photoNumber} value={photoNumber}>
              {photoNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
