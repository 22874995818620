import styled from "styled-components";

export const LoginSection = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const LoginDiv = styled.div`
  width: 450px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #eeeeee80;
  border-radius: 12px;
`;

export const LoginHeading = styled.h1`
  font-size: 2rem;
  letter-spacing: 1px;
  color: rgba(66, 68, 90, 1);
  font-weight: 400;
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 12px;
  padding: 0 12px;
  border: none;
`;

export const LoginButton = styled.button`
  border: none;
  background-color: rgba(66, 68, 90, 1);
  color: white;
  border-radius: 8px;
  padding: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
  width: 160px;
  font-size: 0.85rem;
  letter-spacing: 1px;
  cursor: pointer;
`;

export const Error = styled.p`
  font-size: 0.8rem;
  letter-spacing: 1px;
  color: red;
  font-weight: 500;
  cursor: pointer;
`;
