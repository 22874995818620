import { useNavigate } from "react-router-dom";
import { CloseNav, LeftNavLogo, MobileLeftNavLinks, ModalDiv, ModalOverlay } from "../styles/MobileLeftNav.styled";
import axios from "axios";
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { api_key, api_url } from "../utils/constants";

interface Props {
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbar: React.Dispatch<React.SetStateAction<string>>;
}

const MobileLeftNav: React.FC<Props> = ({ setMobileNavOpen, setSnackbar }) => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    if (!token) return;
    setLoading(true);

    try {
      const response = await axios.post(
        `${api_url}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );
      if (response?.status === 200) {
        setLoading(false);
        setMobileNavOpen(false);
        setSnackbar("Logged out successfully");
        document.cookie = `${token}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
        navigate("/");
        // window.location.href = "/";
        return false;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleNavigate = (link: string) => {
    setMobileNavOpen(false);
    navigate(link);
  };
  return (
    <ModalOverlay>
      <ModalDiv>
        <LeftNavLogo>
          <img
            style={{ width: "200px", height: "40px" }}
            src="https://topshot-brian.s3.us-east-2.amazonaws.com/production/images/logo.png"
            alt="ShareTag"
          />
        </LeftNavLogo>

        <CloseNav>
          <svg
            onClick={() => setMobileNavOpen(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 384 512"
            cursor="pointer"
            fill="white"
          >
            <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
          </svg>
        </CloseNav>

        {loading ? (
          <MobileLeftNavLinks>
            <Oval
              visible={true}
              height="30"
              width="30"
              color="#00b4d8"
              secondaryColor="#00b4d8"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </MobileLeftNavLinks>
        ) : (
          <MobileLeftNavLinks>
            <li onClick={() => handleNavigate("/shared-with-me")}>Shared With Me</li>
            <li onClick={() => handleNavigate("/my-gallery")}>My Gallery</li>
            <li onClick={handleLogout}>Logout</li>
          </MobileLeftNavLinks>
        )}
      </ModalDiv>
    </ModalOverlay>
  );
};

export default MobileLeftNav;
