import axios from "axios";
import { MiddleNav, MiddleNavHamburger, MiddleNavHeading, MiddleNavLogout } from "../styles/MiddleNav.styled";
import { useNavigate } from "react-router";
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { api_key, api_url } from "../utils/constants";

interface Props {
  pageType: string;
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbar: React.Dispatch<React.SetStateAction<string>>;
}

const MiddleNavigation: React.FC<Props> = ({ pageType, setMobileNavOpen, setSnackbar }) => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    if (!token) return;
    setLoading(true);

    try {
      const response = await axios.post(
        `${api_url}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "api-key": api_key
          }
        }
      );
      if (response?.status === 200) {
        setLoading(false);
        setSnackbar("Logged out successfully");
        document.cookie = `${token}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
        navigate("/");
        // window.location.href = "/";
        return false;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <MiddleNav>
      <MiddleNavHeading>{pageType}</MiddleNavHeading>
      {loading ? (
        <Oval
          visible={true}
          height="30"
          width="30"
          color="#00b4d8"
          secondaryColor="#00b4d8"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        <MiddleNavLogout onClick={handleLogout}>Logout</MiddleNavLogout>
      )}

      <MiddleNavHamburger onClick={() => setMobileNavOpen(true)}>
        <svg fill="black" width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
      </MiddleNavHamburger>
    </MiddleNav>
  );
};

export default MiddleNavigation;
