import { Outlet, Navigate, useNavigate } from "react-router-dom";
import MobileLeftNav from "../components/MobileLeftNav";
import LeftNav from "../components/LeftNav";

interface Props {
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileNavOpen: boolean;
  setSnackbar: React.Dispatch<React.SetStateAction<string>>;
}

const PrivateRoutes: React.FC<Props> = ({ setMobileNavOpen, mobileNavOpen, setSnackbar }) => {
  const token = sessionStorage.getItem("token");

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <div style={{ display: "flex" }}>
      <LeftNav />
      {mobileNavOpen && <MobileLeftNav setMobileNavOpen={setMobileNavOpen} setSnackbar={setSnackbar} />}
      <Outlet />
    </div>
  );
};

export default PrivateRoutes;
