import styled from "styled-components";

export const LeftNavDiv = styled.div`
  height: 100dvh;
  width: 15%;
  min-width: 250px;
  background-color: #1d3557;

  @media screen and (max-width: 879px) {
    display: none;
  }
`;

export const LeftNavLogo = styled.div`
  text-align: center;
  margin-top: 1.5rem;
`;

export const LeftNavLinks = styled.ul`
  margin-top: 2rem;
  display: flex;
  font-size: 1.1rem;
  flex-direction: column;
  align-items: left;
  list-style-type: none;
  color: white;
  cursor: pointer;
  font-weight: 300;

  li {
    /*padding-left: 2rem;
    padding-top: 2rem;*/
    padding: 2rem;
  }
`;
