import styled from "styled-components";

export const Main = styled.div`
  min-height: 100dvh;
  width: 100%;

  @media screen and (max-width: 879px) {
    width: 100%;
  }
`;

export const MiddleNavHeading = styled.h2`
  font-size: 1.8rem;
  letter-spacing: 1px;
  color: rgba(66, 68, 90, 1);
  font-weight: 400;
`;

export const MiddlePhotosGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 1rem 2rem;
  height: 90dvh;
  overflow: auto;

  @media screen and (max-width: 879px) {
    justify-content: center;
  }
`;

export const MiddlePhoto = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 2px;
  -webkit-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 6px -2px rgba(66, 68, 90, 1);
  background-size: cover;
  background-position: center;
  padding: 1rem;
  border: 6px solid white;
  position: relative;
  @media screen and (max-width: 879px) {
    width: 100px;
    height: 100px;
  }
`;

export const PageDiv = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  padding: 1rem;
`;
