import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { LoginSection, LoginDiv, LoginHeading, Input, LoginButton, Error } from "../styles/Login.styled";
import { api_key, api_url } from "../utils/constants";

interface Props {
  setSnackbar: React.Dispatch<React.SetStateAction<string>>;
}

const Login: React.FC<Props> = ({ setSnackbar }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    setLoading(true);

    const requestData = {
      email: email,
      password: password
    };
    const headers = {
      "api-key": api_key
    };

    try {
      if (api_url) {
        const response = await axios.post(`${api_url}/login`, requestData, {
          headers
        });
        if (response?.status === 200) {
          setSnackbar("Logged in successfully");
          setLoading(false);
          setToken(response?.data?.access_token);
        }
      }
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("token", token);
      // document.cookie = `Bearer ${token};secure=true;samesite=strict`;
      navigate("/my-gallery");
    }
  }, [token]);
  return (
    <LoginSection
      style={{
        backgroundImage: `url(https://images.pexels.com/photos/3024996/pexels-photo-3024996.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)`
      }}
    >
      <LoginDiv>
        <LoginHeading>Login</LoginHeading>

        <Input value={email} onChange={handleEmailChange} placeholder="Email"></Input>
        <Input value={password} type="password" onChange={handlePasswordChange} placeholder="Password"></Input>

        {loading ? (
          <Oval
            visible={true}
            height="40"
            width="40"
            color="rgba(66, 68, 90, 1)"
            secondaryColor="rgba(66, 68, 90, 1)"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ) : (
          <LoginButton onClick={handleLogin}>Submit</LoginButton>
        )}

        {error && <Error>{error}</Error>}
      </LoginDiv>
    </LoginSection>
  );
};

export default Login;
